import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import { RestrictionsDialogStateContainer } from '@confluence/restrictions-dialog-state-container';
import { useSessionData } from '@confluence/session-data';

import { startRestrictionsDialogExperience } from './RestrictionsDialogTrigger';

const label = (
	<FormattedMessage
		id="restrictions.dialog.link.text"
		defaultMessage="Restrictions"
		description="Text on the link in actions menu that opens page restrictions dialog"
	/>
);

type RestrictionsDialogMenuPropsProviderProps = {
	children?: (itemProps: any) => ReactNode;
	contentId: string;
};

export function RestrictionsDialogMenuPropsProvider({
	children,
	contentId,
}: RestrictionsDialogMenuPropsProviderProps) {
	const { edition } = useSessionData();
	const useAnalyticsEventsHook = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	// Note: this is used for balance A/B testing user cohorts, it will be replaced with real feature flag later.

	return children && edition ? (
		<Subscribe to={[RestrictionsDialogStateContainer]}>
			{(dialogStateContainer: RestrictionsDialogStateContainer) =>
				children({
					id: 'bm-restrictions-link',
					label,

					onClick() {
						startRestrictionsDialogExperience({
							...useAnalyticsEventsHook,
							actionSubjectId: 'restrictionsDialogLink',
							dialogStateContainer,
							edition,
							experienceTracker,
							triggeredFrom: 'actionsMenu',
							contentId,
						});
					},
				})
			}
		</Subscribe>
	) : null;
}
