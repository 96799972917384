import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N300A } from '@atlaskit/theme/colors';

import { useSessionData } from '@confluence/session-data';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import { SECTION_MESSAGE_APPEARANCE_TYPES } from '../constants';
import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { SectionMessage } from '../UpsellLooknFeel/SectionMessage';

interface ExportPLUpsellProps {
	onClick?(): void;
}

const i18n = defineMessages({
	title: {
		id: 'change-edition.export-upsell.title',
		defaultMessage: 'Share as public link',
		description: 'info message title for public link upsell',
	},
	text: {
		id: 'change-edition.export-upsell.text',
		defaultMessage:
			'It’s easier to make changes and keep everyone on the same page with a public link.',
		description: 'info message text for public link upsell',
	},
});

const PL_EXPORT_UPSELL_SOURCE = 'publicLinksExportUpsell';
const LEARN_MORE_PUBLIC_LINKS_HREF =
	'https://support.atlassian.com/confluence-cloud/docs/share-content-externally-with-public-links/';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExportsPLUpsellContainer = styled.div({
	maxWidth: '280px',
	cursor: 'default',
	borderTop: `1px solid ${token('color.border', N300A)}`,
});

export const ExportsPLUpsellComponent: FC<ExportPLUpsellProps> = ({ onClick }) => {
	const { edition } = useSessionData();

	const shouldShowExportUpsell = edition === ConfluenceEdition.FREE;

	return (
		<>
			{shouldShowExportUpsell && (
				<ExportsPLUpsellContainer onClick={(e) => e.stopPropagation()}>
					<SectionMessage
						title={<FormattedMessage {...i18n.title} />}
						body={<FormattedMessage {...i18n.text} />}
						appearanceType={SECTION_MESSAGE_APPEARANCE_TYPES.INLINE}
						source={PL_EXPORT_UPSELL_SOURCE}
						learnMoreLink={{
							href: LEARN_MORE_PUBLIC_LINKS_HREF,
							onClick,
						}}
						targetEdition={ConfluenceEdition.STANDARD}
						onUpFlowOpen={onClick}
						withGradient={false}
					/>
				</ExportsPLUpsellContainer>
			)}
		</>
	);
};

export const ExportsPLUpsell = withTransparentErrorBoundary({
	attribution: Attribution.EXTERNAL_COLLAB,
})(ExportsPLUpsellComponent);
