import gql from 'graphql-tag';

export const ConvertPageToFolderMutation = gql`
	mutation ConvertPageToFolderMutation($id: ID!) {
		convertToFolder(id: $id) {
			content {
				id
			}
		}
	}
`;
