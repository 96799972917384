import { useQuery } from '@apollo/react-hooks';

import { isExpectedApolloError } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { markErrorAsHandled } from '@confluence/graphql';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { isViewPage } from '@confluence/named-routes';

import { ConvertPageToFolderQuery } from './ConvertPageToFolderQuery.graphql';
import type {
	ConvertPageToFolderQuery as ConvertPageToFolderQueryType,
	ConvertPageToFolderQueryVariables,
} from './__types__/ConvertPageToFolderQuery';

export const CONVERT_PAGE_TO_FOLDER_WEBITEM_ID = 'action-convert-page-to-folder';

export const useCanConvertPageToFolder = ({
	contentId,
	spaceKey,
	skipQuery,
}: {
	contentId: string;
	spaceKey: string;
	skipQuery?: boolean;
}) => {
	const { data, loading, error } = useQuery<
		ConvertPageToFolderQueryType,
		ConvertPageToFolderQueryVariables
	>(ConvertPageToFolderQuery, {
		skip: Boolean(skipQuery) || !fg('confluence_enable_page_to_folder_conversion'),
		variables: { contentId, spaceKey },
	});

	const isViewPageRoute = isViewPage();
	const isLivePage = useIsLivePage();
	const isPage = data?.singleContent?.type === 'page';
	const isFoldersEnabledForSpace =
		data?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled;
	const isPublished = data?.singleContent?.status === 'current';
	const canEdit = data?.singleContent?.operations?.some(
		(operationCheckResult) =>
			operationCheckResult?.operation === 'update' && operationCheckResult?.targetType === 'page',
	);

	const canConvertPageToFolder = Boolean(
		isViewPageRoute && !isLivePage && isPage && isFoldersEnabledForSpace && isPublished && canEdit,
	);

	if (isExpectedApolloError(error)) {
		markErrorAsHandled(error);
	}

	const contentTitle = data?.singleContent?.title;
	const hasLoaded = evalHasLoaded(
		Boolean(skipQuery) || !fg('confluence_enable_page_to_folder_conversion'),
		Boolean(data),
		Boolean(error),
		loading,
	);

	return {
		canConvertPageToFolder,
		contentTitle,
		loading,
		error,
		hasLoaded,
	};
};

export const evalHasLoaded = (
	skip: boolean,
	hasData: boolean,
	hasError: boolean,
	loading: boolean,
) => {
	return skip || ((hasData || hasError) && !loading);
};
