import { PureComponent } from 'react';
import type { ExtendedKeyboardEvent, MousetrapStatic } from 'mousetrap';
import Mousetrap from 'mousetrap';

// @ts-ignore "@confluence/dialogs" will be converted to "stricter" separately
import { isDialogShown } from '@confluence/dialogs';

import { MOUSETRAP_ALLOWED_ELEMENTS, MOUSETRAP_ALLOWED_MODALS } from './constants';

if (typeof window !== 'undefined') {
	// allow to track events on specific elements
	const originalStopCallback: MousetrapStatic['stopCallback'] = Mousetrap.prototype.stopCallback;
	// Note here should be function instead of arrow function as we want to preserve the context of this
	Mousetrap.prototype.stopCallback = function (
		...[e, element, combo]: Parameters<MousetrapStatic['stopCallback']>
	) {
		const isAllowed = MOUSETRAP_ALLOWED_ELEMENTS.map((className) =>
			element.classList.contains(className),
		).some((truthful) => truthful);

		const isAllowedInModal = MOUSETRAP_ALLOWED_MODALS.map((className) =>
			element.closest(`.${className}`),
		).some((truthful) => truthful);

		return isAllowed || isAllowedInModal
			? false
			: originalStopCallback.call(this, e, element, combo);
	};
}
const ALLOWED_MIN_TIME = 150;

export type ShortcutEvent = ExtendedKeyboardEvent;

type ShortcutConflict = {
	accelerator: string;
	conflictType: ShortcutType | undefined;
};

export enum ShortcutType {
	Confluence = 'Confluence',
	Forge = 'Forge',
}

export type ShortcutListenerProps = {
	/**
	 * A mousetrap accelerator
	 * Describes a keyboard combination
	 * E.g. ctrl+a
	 */
	accelerator: string | string[];
	/**
	 * If true, this keyboard shortcut can be activated while a dialog box is open.
	 */
	allowInDialog?: boolean;
	/**
	 * The function to call when the shortcut is triggered
	 */
	listener(e: ShortcutEvent): void;
	/**
	 * Level of priority for overlapping shortcuts.
	 * Confluence keyboard shortcuts will take priority over custom Forge ones.
	 */
	shortcutType: ShortcutType;
};

export class ShortcutListener extends PureComponent<ShortcutListenerProps> {
	public static defaultProps = {
		allowInDialog: false,
		shortcutType: ShortcutType.Confluence,
	};

	componentDidMount() {
		const { accelerator, shortcutType } = this.props;
		this.checkConflictThenBindAccelerator(accelerator, shortcutType);
	}

	componentDidUpdate(prevProps: Readonly<ShortcutListenerProps>): void {
		const { accelerator: prevAccelerator } = prevProps;
		const { accelerator, shortcutType } = this.props;
		const prevAccelerators: string[] = this.getAccelerators(prevAccelerator);

		// Using JSON.stringify to compare because accelerator may be a list of strings
		if (JSON.stringify(prevAccelerator) !== JSON.stringify(accelerator)) {
			this.unbindAccelerators(prevAccelerators);
			this.checkConflictThenBindAccelerator(accelerator, shortcutType);
		}
	}

	componentWillUnmount() {
		const { accelerator } = this.props;
		const accelerators: string[] = this.getAccelerators(accelerator);

		this.unbindAccelerators(accelerators);
	}

	getAccelerators(accelerator: string | string[]): string[] {
		return Array.isArray(accelerator) ? accelerator : [accelerator];
	}

	getShortcutConflicts(accelerators: string[]): ShortcutConflict[] {
		return accelerators
			.map((a) => ({
				accelerator: a,
				conflictType: ShortcutListener.listeners[a],
			}))
			.filter((o) => o.conflictType);
	}

	isConfluenceShortcutAndAllConflictsAreForgeShortcuts(
		shortcutType: ShortcutType,
		shortcutConflicts: ShortcutConflict[],
	): boolean {
		return (
			shortcutType === ShortcutType.Confluence &&
			shortcutConflicts.every((o) => o.conflictType === ShortcutType.Forge)
		);
	}

	checkConflictThenBindAccelerator(accelerator: string | string[], shortcutType: ShortcutType) {
		const accelerators: string[] = this.getAccelerators(accelerator);

		const shortcutConflicts: ShortcutConflict[] = this.getShortcutConflicts(accelerators);
		if (!shortcutConflicts.length) {
			this.bindAccelerators(accelerators, shortcutType);
		} else if (
			this.isConfluenceShortcutAndAllConflictsAreForgeShortcuts(shortcutType, shortcutConflicts)
		) {
			shortcutConflicts.forEach((c) => Mousetrap.unbind(c.accelerator));
			this.bindAccelerators(accelerators, shortcutType);
		} else if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line no-console
			console.error(
				`Registered duplicate ShortcutListener: ${accelerator}. There should only be one component that binds this listener mounted at a time.`,
			);
		}
	}

	bindAccelerators(accelerators: string[], shortcutType: ShortcutType) {
		accelerators.forEach((a) => {
			Mousetrap.bind(a, this._call);
			ShortcutListener.listeners[a] = shortcutType;
		});
	}

	unbindAccelerators(accelerators: string[]) {
		accelerators.forEach((a) => {
			Mousetrap.unbind(a);
			delete ShortcutListener.listeners[a];
		});
	}

	public static listeners: Record<string, ShortcutType> = {};

	listeners = {};
	lastFired = 0;

	_call = (e: ShortcutEvent) => {
		// User can only fire a shortcut event in every 150ms
		// This can avoid a part of sequence being recognized as a single key shortcut
		// eg: "s" will be triggered when user enters "g s" without this
		const now = Date.now();
		if (now - this.lastFired >= ALLOWED_MIN_TIME) {
			if (this.props.allowInDialog || !isDialogShown()) {
				this.lastFired = now;
				this.props.listener(e);
			}
		}
	};

	render() {
		return null;
	}
}
