import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

type AutoConversionSetting = {
	isAllPagesEnabled?: boolean;
};

type PageAnalyticsOptions = {
	pageAction: string;
	source?: string;
	setting?: AutoConversionSetting;
};

type PanelAnalyticsOptions = {
	panelAction: string;
	level: string;
	source?: string;
};

export enum AutoConvertEvent {
	VIEWED = 'pageViewed',
	EDITED = 'pageEdited',
	PUBLISHED = 'pagePublished',
	NOTIFICATION_SHOWN = 'notificationShown',
	NOTIFICATION_ERROR = 'notificationError',
}

export enum AutoConvertActionSubject {
	AUTO_CONVERSION = 'autoConversion',
	AUTO_CONVERSION_BYLINE = 'autoConversionByLine',
}

export enum RevertAutoConvertEvent {
	DIALOG_OPEN = 'revertDialogOpen',
	CLICKED = 'revertClicked',
	CANCELLED = 'revertCancelled',
	CONFIRMED = 'revertConfirmed',
	ERROR = 'revertError',
}

export enum AutoConvertPanelEvent {
	VIEWED = 'panelViewed',
	SUPPORTED = 'panelSupported',
	NONE = 'panelNone',
	GET_ERROR = 'panelGetError',
	SET_ERROR = 'panelSetError',
}

export enum AutoConversionByLineEvent {
	CLICKED = 'bylineClicked',
	NEW_EDITOR_MODAL_OPENED = 'newEditorModalOpened',
	NEW_EDITOR_MODAL_CLOSED = 'newEditorModalClosed',
}

// Converted page event
export const sendEditorConversionAnalyticsPageEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	analytics: PageAnalyticsOptions,
	actionSubject: AutoConvertActionSubject,
) => {
	const { pageAction, source, setting } = analytics;

	createAnalyticsEvent({
		type: 'sendUIEvent',
		data: {
			action: 'triggered',
			actionSubject,
			source,
			actionSubjectId: 'page',
			attributes: {
				pageAction,
				setting,
			},
		},
	}).fire();
};

// Space Setting Event
export const sendEditorConversionAnalyticsPanelEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	analytics: PanelAnalyticsOptions,
) => {
	const { level, panelAction, source } = analytics;

	createAnalyticsEvent({
		type: 'sendUIEvent',
		data: {
			action: 'clicked',
			actionSubject: 'autoConversion',
			source,
			actionSubjectId: 'panel',
			attributes: {
				level,
				panelAction,
			},
		},
	}).fire();
};
