import * as confluenceShortcuts from './confluence-shortcuts';
import { getShortcutValues } from './util';

export const ALLOW_SHORTCUTS_CLASS = 'shortcuts-are-allowed';
export const ALLOW_SHORTCUTS_CLASS_IN_MODAL = 'shortcuts-are-allowed-in-modal';
export const MOUSETRAP_ALLOWED_ELEMENTS = ['ProseMirror-focused', ALLOW_SHORTCUTS_CLASS];

export const MOUSETRAP_ALLOWED_MODALS = [ALLOW_SHORTCUTS_CLASS_IN_MODAL];

export const RESERVED_SHORTCUTS = Object.values(confluenceShortcuts).reduce(getShortcutValues, []);
