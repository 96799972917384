import React from 'react';

import { useThemingState } from './useThemingState';

// HOC for places that cannot use React hooks
export const withThemingState = <P extends object>(Component: React.ComponentClass<P>) => {
	return (props) => {
		const [{ theme }, themingActions] = useThemingState();

		return <Component theme={theme} themingActions={themingActions} {...props} />;
	};
};
