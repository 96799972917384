import gql from 'graphql-tag';

export const EndOfPageRecHideStatusMutation = gql`
	mutation EndOfPageRecHideStatusMutation($type: String!, $id: ID!, $status: Boolean!) {
		setRecommendedPagesStatus(
			input: { entityType: $type, entityId: $id, enableRecommendedPages: $status }
		) {
			success
			errors {
				message
			}
		}
	}
`;
