import { PAGE_ANALYTICS } from '@confluence/named-routes';

// Replace a relative URL containing query params with the new native UI route URL with the query params
export const pageAnalyticsUrlToRouteUrl = (relativeUrl: string): string => {
	if (!relativeUrl) {
		return '';
	}

	// First, extract everything in the relative URL after the '?' in the URL
	const queryParams = new URLSearchParams(relativeUrl.split('?')[1] || '');

	// Extract the space key and content ID from the query params
	const spaceKey = queryParams.get('space.key') || '';
	const contentId = queryParams.get('content.id') || '';

	// TODO - CCIR-11: Remove the following comment once the analytics migration is complete:
	// PAGE_ANALYTICS route code will redirect to the correct URL based on the feature gate and
	// FedRAMP environment
	return `${PAGE_ANALYTICS.toUrl({ spaceKey, contentId })}?${queryParams.toString()}`;
};
