import React, { type FC } from 'react';

import { ExportsPLUpsell } from '@confluence/change-edition/entry-points/ExportsPLUpsell';
import { LoadableLazy } from '@confluence/loadable';

interface PublicLinksExportMenuEntryPointProps {
	closeDropdownMenu: () => void;
	contentId: string;
}

export const ExportShareAsPublicLink = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExportShareAsPublicLink" */ './ExportShareAsPublicLink'
			)
		).ExportShareAsPublicLink,
});

export const PublicLinksExportMenuEntryPoint: FC<PublicLinksExportMenuEntryPointProps> = ({
	closeDropdownMenu,
	contentId,
}) => (
	<>
		<ExportsPLUpsell onClick={closeDropdownMenu} />
		<ExportShareAsPublicLink contentId={contentId} />
	</>
);
