import gql from 'graphql-tag';

export const ContentOwnerQuery = gql`
	query ContentOwnerQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			history {
				createdBy {
					displayName
					... on KnownUser {
						accountId
					}
					... on User {
						accountId
					}
				}
				ownedBy {
					displayName
					... on KnownUser {
						accountId
					}
					... on User {
						accountId
					}
				}
			}
		}
	}
`;
