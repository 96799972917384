import type { FC } from 'react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';

import { ErrorDisplay, ErrorBoundary, Attribution } from '@confluence/error-boundary';

import { i18n } from './i18n';
import { EndOfPageRecHideStatusMutation } from './EndOfPageRecHideStatusMutation.graphql';
import { EndOfPageRecommendationQuery } from './EndOfPageRecommendationQuery.graphql';

type EndOfPageHideConfirmModalProps = {
	entityType: string;
	entityId: string;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	spaceKey: string;
};

export const EndOfPageHideConfirmModal: FC<EndOfPageHideConfirmModalProps> = ({
	entityId,
	entityType,
	isOpen,
	setIsOpen,
	spaceKey,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [mutateHideStatus, { error }] = useMutation(EndOfPageRecHideStatusMutation, {
		refetchQueries: [
			{
				query: EndOfPageRecommendationQuery,
				variables: {
					type: 'page',
					id: entityId,
					exp: 'end_of_page',
					key: spaceKey,
				},
			},
		],
	});

	const cancelModal = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'hideEOPModalCancel',
				attributes: {
					contentId: entityId,
				},
			},
		}).fire();
		setIsOpen(false);
	}, [createAnalyticsEvent, setIsOpen, entityId]);

	const confirmModal = useCallback(() => {
		void mutateHideStatus({
			variables: {
				type: entityType,
				id: entityId,
				status: false,
			},
		}).catch(() => {
			// This is literally just to prevent jest from failing tests on error
			// behavior due to apparent "uncaught promise rejection." We actually
			// handle mutation error by deconstructing it above in the mutation hook
			// above and rendering an error component below.
		});

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'hideEOPModalConfirm',
				attributes: {
					contentId: entityId,
				},
			},
		}).fire();
		setIsOpen(false);
	}, [createAnalyticsEvent, mutateHideStatus, setIsOpen, entityId, entityType]);

	if (error) {
		return (
			<ErrorBoundary attribution={Attribution.SMARTS}>
				<ErrorDisplay error={error} />
			</ErrorBoundary>
		);
	}

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={cancelModal} testId="eop-hide-modal">
					<ModalHeader>
						<ModalTitle>
							<FormattedMessage
								{...i18n.modal_title}
								values={{
									b: (boldWord: React.ReactNode[]) => <b>{boldWord}</b>,
								}}
							/>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<FormattedMessage {...i18n.modal_info} />
						<br />
						<br />
						<FormattedMessage {...i18n.modal_info_show} />
					</ModalBody>
					<ModalFooter>
						<Button appearance="subtle" onClick={cancelModal} testId="eop-hide-modal-cancel">
							<FormattedMessage {...i18n.modal_cancel} />
						</Button>
						<Button
							appearance="primary"
							onClick={confirmModal}
							testId="eop-hide-modal-confirm"
							autoFocus
						>
							<FormattedMessage {...i18n.modal_hide} />
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	);
};
