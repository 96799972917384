import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { WebItemAnalyticsWrapper } from '@confluence/analytics';
import { ANALYTICS_ADDON_BYLINE_ID } from '@confluence/web-item';

const getAnalyticsData = (webItemId) => {
	const fromName = webItemId === ANALYTICS_ADDON_BYLINE_ID ? 'fromByline' : 'fromActionMenu';
	const event = {
		type: 'sendUIEvent',
		data: {
			action: 'clicked',
			source: 'viewPageScreen',
			actionSubject: 'AnalyticsButton',
			attributes: {
				from: fromName,
				type: 'viewers',
			},
		},
	};
	return event;
};

export class AnalyticsPluginWrapper extends PureComponent {
	static propTypes = {
		webItemId: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
	};

	render() {
		const { webItemId } = this.props;
		const analyticsData = getAnalyticsData(webItemId);
		return (
			<WebItemAnalyticsWrapper analyticsData={analyticsData}>
				{this.props.children}
			</WebItemAnalyticsWrapper>
		);
	}
}
