import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import AkButton from '@atlaskit/button/custom-theme-button';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment';
import { N400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { WebItemAnalyticsWrapper } from '@confluence/analytics';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { usePageContentId } from '@confluence/page-context';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewAttachmentsButtonContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		cursor: 'pointer',
		height: '30px',
		width: '30px',
		padding: 0,
		margin: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:hover': {
		color: token('color.text.subtle', N400),
	},
});

const i18n = defineMessages({
	attachments: {
		id: 'attachments.view-attachments-button',
		defaultMessage: 'View attachments',
		description:
			'Explaining that click on a button will take user to a list of attachments for the page',
	},
});

const ATTACHMENTS_ANALYTICS_FROM_VIEW = {
	type: 'sendUIEvent',
	data: {
		action: 'clicked',
		actionSubject: 'ViewAttachmentsButton',
		source: 'attachments',
		attributes: {
			triggeredFrom: 'fromView',
		},
	},
};

export const ATTACHMENTS_ANALYTICS_FROM_ACTIONS_MENU = {
	type: 'sendUIEvent',
	data: {
		action: 'clicked',
		actionSubject: 'ViewAttachmentsButton',
		source: 'attachments',
		attributes: {
			triggeredFrom: 'actionsMenu',
		},
	},
};

export const ViewAttachmentsButtonComponent = ({ intl }) => {
	const label = intl.formatMessage(i18n.attachments);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [contentId] = usePageContentId();

	createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			actionSubject: 'feature',
			action: 'exposed',
			source: 'ViewAttachmentsButton',
			attributes: {
				isPageSSRd: window.__SSR_RENDERED__,
			},
		},
	}).fire();

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<ViewAttachmentsButtonContainer>
				<AkButton appearance="subtle">
					<WebItemAnalyticsWrapper analyticsData={ATTACHMENTS_ANALYTICS_FROM_VIEW}>
						<a
							href={`${CONTEXT_PATH}/pages/viewpageattachments.action?pageId=${contentId}&metadataLink=true`}
						>
							<AttachmentIcon label={label} />
						</a>
					</WebItemAnalyticsWrapper>
				</AkButton>
			</ViewAttachmentsButtonContainer>
		</ErrorBoundary>
	);
};

ViewAttachmentsButtonComponent.propTypes = {
	intl: PropTypes.object,
};

export const ViewAttachmentsButton = injectIntl(ViewAttachmentsButtonComponent);
