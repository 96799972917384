import type { FC, ComponentType } from 'react';
import React from 'react';

import type { SessionDataType } from './SessionDataTypes';
import { useSessionData } from './useSessionData';

export type SessionDataContextInjected = {
	sessionData: SessionDataType | undefined;
};

export function withSessionData<TOriginalProps>(
	WrappedComponent: ComponentType<TOriginalProps & SessionDataContextInjected>,
): FC<Pick<TOriginalProps, Exclude<keyof TOriginalProps, keyof SessionDataContextInjected>>> {
	function WithSessionData(
		props: Pick<TOriginalProps, Exclude<keyof TOriginalProps, keyof SessionDataContextInjected>>,
	) {
		const sessionData: SessionDataType = useSessionData();
		return <WrappedComponent sessionData={sessionData} {...(props as TOriginalProps)} />;
	}

	WithSessionData.displayName = `withSessionData(${
		WrappedComponent.displayName || WrappedComponent.name
	})`;

	return WithSessionData;
}
