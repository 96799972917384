import type { ComponentProps } from 'react';
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { GeneralShortcutListener } from '@confluence/shortcuts';
import {
	openMenuClick,
	simulateItemClick,
} from '@confluence/legacy-keyboard-shortcut-helpers/entry-points/legacyKeyboardShortcutHelpers';

export class Shortcuts extends PureComponent<ShortcutsProps> {
	static propTypes = {
		keyboardShortcuts: PropTypes.array,
		setActiveKeyboardShortcutHandler: PropTypes.func,
		isAppNavigationShortcut: PropTypes.bool,
	};

	createListener(itemId) {
		return () => {
			this.props.setActiveKeyboardShortcutHandler(() => {
				simulateItemClick(itemId);
			});
			openMenuClick();
		};
	}

	render() {
		const { keyboardShortcuts, isAppNavigationShortcut } = this.props;

		return keyboardShortcuts.length > 0 ? (
			<Fragment>
				{keyboardShortcuts.map(({ accelerator, itemId }) => (
					<GeneralShortcutListener
						key={Array.isArray(accelerator) ? accelerator.join('+') : accelerator}
						accelerator={accelerator}
						listener={this.createListener(itemId)}
						isAppNavigationShortcut={isAppNavigationShortcut}
					/>
				))}
			</Fragment>
		) : null;
	}
}

export type ShortcutsProps = {
	keyboardShortcuts: Array<{
		accelerator: GeneralShortcutListenerProps['accelerator'];
		itemId: string;
	}>;
	setActiveKeyboardShortcutHandler: (handler: () => void) => void;
} & Pick<GeneralShortcutListenerProps, 'isAppNavigationShortcut'>;

type GeneralShortcutListenerProps = ComponentProps<typeof GeneralShortcutListener>;
