import gql from 'graphql-tag';

export const EndOfPageRecHideQuery = gql`
	query EndOfPageRecHideQuery($type: String!, $id: ID!, $exp: String!, $key: ID!) {
		getRecommendedPages(entityType: $type, entityId: $id, experience: $exp) {
			recommendedPages {
				content {
					id
				}
			}
			status {
				userCanToggle
				isEnabled
			}
		}
		getRecommendedPagesSpaceStatus(entityId: $key) {
			recommendedPagesEnabled
		}
	}
`;
