import gql from 'graphql-tag';

export const ConvertPageToFolderBannerQuery = gql`
	query ConvertPageToFolderBannerQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			children(first: 1) {
				count
			}
			body {
				dynamic {
					value
				}
			}
		}
	}
`;
