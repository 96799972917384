import { LoadableAfterPaint } from '@confluence/loadable';

export const CopyPageTreeDialog = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CopyPageTreeContainer" */ './CopyPageTreeContainer'
			)
		).CopyPageTreeContainer,
});
