import { LoadableLazy } from '@confluence/loadable';

export const RevertEditorDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RevertEditorDialog" */ '../src/EditorRevert/RevertEditorDialog'
			)
		).RevertEditorDialog,
});
