import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

export const ResolvedInlineCommentsDialogLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ResolvedInlineCommentsDialog" */ './ResolvedInlineCommentsDialog'
			)
		).ResolvedInlineCommentsDialog,
});

export const ResolvedInlineCommentsQueryRendererLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ResolvedInlineCommentsQueryRenderer" */ './ResolvedInlineCommentsQueryRenderer'
			)
		).ResolvedInlineCommentsQueryRenderer,
});

export const ResolvedInlineCommentsCounterLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ResolvedInlineCommentsCounter" */ './ResolvedInlineCommentsCounter'
			)
		).ResolvedInlineCommentsCounter,
});
