/* eslint-disable react/display-name */
import React from 'react';

import { LoadableLazy, LoadablePaint } from '@confluence/loadable';

import { PresentationModePlaceholder } from './PresentationModePlaceholder';

export const PresenterModeContextProvider = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PresenterModeContext" */ './PresenterModeContext'))
			.PresenterModeContextProvider,
});

export const LazyPresenterModeContextConsumer = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PresenterModeContext" */ './PresenterModeContext'))
			.PresenterModeContextConsumer,
	loading: () => <PresentationModePlaceholder />,
});
