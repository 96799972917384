import { createStore, createHook } from 'react-sweet-state';

type State = {
	bannerDismissed: boolean;
};

export const actions = {
	dismissBanner:
		() =>
		({ setState }: { setState: any }) => {
			setState({ bannerDismissed: true });
		},
};

export const useConvertPageToFolderStore = (bannerDismissed = false) => {
	const initialState: State = { bannerDismissed };

	const Store = createStore({
		initialState,
		actions,
		name: 'convertPageToFolderStore',
	});

	return createHook(Store)();
};
