import type { ComponentProps, ReactNode } from 'react';
import React, { PureComponent, createContext } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N40A } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';

import {
	VIEW_PAGE_CONTENT_TOOLS_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { EndOfPageHideConfirmModal } from '@confluence/page-recommendations';

import { Shortcuts, type ShortcutsProps } from './Shortcuts';
import { ContentToolsButton, type ContentToolsButtonProps } from './ContentToolsButton';
import { MORE_ACTIONS_DROPDOWN_METRIC } from './perf.config';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SeparatedGroup = styled.div({
	borderBottom: `2px solid ${token('color.border', N40A)}`,
	paddingBottom: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		borderBottom: 0,
		paddingBottom: 0,
		marginBottom: 0,
	},
});

export const ContentToolsDropdownOverrideContext = createContext((_: boolean) => {});

export class ContentToolsComponentClass extends PureComponent<ContentToolsComponentClassProps> {
	static defaultProps = {
		keyboardShortcuts: [],
	};

	state = {
		isMenuChangeAllowed: true,
		isEndOfPageHideConfirmOpen: false,
	};

	activeKeyboardShortcutHandler: null | (() => void) = null;

	setActiveKeyboardShortcutHandler: ShortcutsProps['setActiveKeyboardShortcutHandler'] = (
		activeKeyboardShortcutHandler,
	) => {
		this.activeKeyboardShortcutHandler = activeKeyboardShortcutHandler;
	};

	onPositioned = () => {
		MORE_ACTIONS_DROPDOWN_METRIC.stop();
	};

	handleOpenChange: ContentToolsButtonProps['onOpenChange'] = ({ isOpen }) => {
		if (!this.state.isMenuChangeAllowed) {
			return;
		}

		if (isOpen) {
			MORE_ACTIONS_DROPDOWN_METRIC.start();
		}
		this.props.onOpenChange?.(isOpen);
		if (this.activeKeyboardShortcutHandler) {
			this.activeKeyboardShortcutHandler();
			this.activeKeyboardShortcutHandler = null;
		}
		this.fireToggleAnalyticsEvent();
	};

	setMenuChangeAllowed = (allowed) => {
		this.setState({ isMenuChangeAllowed: allowed });
	};

	closeDialog = () => {
		this.props.onOpenChange?.(false);
		this.setState({ isMenuChangeAllowed: true });
	};

	setIsEndOfPageHideConfirmOpen = (isOpen) => {
		this.setState({ isEndOfPageHideConfirmOpen: isOpen });
	};

	fireToggleAnalyticsEvent = () => {
		const { createAnalyticsEvent } = this.props;
		createAnalyticsEvent?.({
			data: {
				action: 'clicked',
				actionSubject: 'moreIcon',
				source: 'moreActions',
				attributes: {
					componentVersion: 'v2',
				},
			},
			type: 'sendTrackEvent',
		}).fire();
	};

	render() {
		const {
			itemSections,
			keyboardShortcuts,
			contentId,
			contentType,
			preloadCopyItem,
			preloadMoveItem,
			spaceKey,
			isExternalCollaborator,
			isAppNavigationShortcut,
			isMenuOpen,
		} = this.props;
		const { isEndOfPageHideConfirmOpen } = this.state;

		return (
			<ContentToolsDropdownOverrideContext.Provider value={this.setMenuChangeAllowed}>
				<Shortcuts
					keyboardShortcuts={keyboardShortcuts}
					setActiveKeyboardShortcutHandler={this.setActiveKeyboardShortcutHandler}
					isAppNavigationShortcut={isAppNavigationShortcut}
				/>
				<Tooltip
					content={
						<FormattedMessage
							id="content-tools.button.tooltip"
							defaultMessage="More actions"
							description="Tooltip for button that opens a menu providing additional actions for a page"
						/>
					}
					hideTooltipOnClick
					position="bottom"
				>
					<ContentToolsButton
						isDisabled={false}
						isOpen={isMenuOpen}
						onOpenChange={this.handleOpenChange}
						onPositioned={this.onPositioned}
						isLoading={false}
						preloadCopyItem={preloadCopyItem}
						preloadMoveItem={preloadMoveItem}
						isExternalCollaborator={isExternalCollaborator}
					>
						{itemSections.map((section, index) => {
							// index should be safe as key because it's ordered
							return <SeparatedGroup key={index}>{section}</SeparatedGroup>;
						})}
					</ContentToolsButton>
				</Tooltip>
				<EndOfPageHideConfirmModal
					entityId={contentId}
					// @ts-expect-error `contentType` is not defined always, but this predates the TypeScript support here
					entityType={contentType}
					spaceKey={spaceKey}
					isOpen={isEndOfPageHideConfirmOpen}
					setIsOpen={this.setIsEndOfPageHideConfirmOpen}
				/>
				<ExperienceSuccess name={VIEW_PAGE_CONTENT_TOOLS_EXPERIENCE} />
			</ContentToolsDropdownOverrideContext.Provider>
		);
	}
}

type ContentToolsComponentClassProps = ContentToolsComponentProps & WithAnalyticsEventsProps;

export const ContentToolsComponent = withAnalyticsEvents()(ContentToolsComponentClass);

export type ContentToolsComponentProps = {
	itemSections: ReadonlyArray<NonNullable<ReactNode>>;
	onOpenChange?: (isOpen: boolean) => void;
	isMenuOpen: boolean;

	// Pass-through props for EndOfPageHideConfirmModal:
	contentId: EndOfPageHideConfirmModalProps['entityId'];
	contentType?: EndOfPageHideConfirmModalProps['entityType'];
	spaceKey: EndOfPageHideConfirmModalProps['spaceKey'];
} & Pick<
	ContentToolsButtonProps, // Pass-through props for ContentToolsButton:
	'isExternalCollaborator' | 'preloadCopyItem' | 'preloadMoveItem'
> &
	Pick<
		ShortcutsProps, // Pass-through props for Shortcuts:
		'isAppNavigationShortcut' | 'keyboardShortcuts'
	>;

type EndOfPageHideConfirmModalProps = ComponentProps<typeof EndOfPageHideConfirmModal>;
