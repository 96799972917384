import type { FC, ReactNode } from 'react';
import React from 'react';

import Tooltip from '@atlaskit/tooltip/Tooltip';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	ShortcutType,
	RESERVED_SHORTCUTS,
} from '@confluence/shortcuts';

import type { ConfluenceForgeUIExtensionType } from '../../types';

type ForgeKeyboardShortcutVisualizerProps = {
	module: ConfluenceForgeUIExtensionType;
	tooltip?: string;
	children: ReactNode;
};

type ForgeKeyboardShortcutProps = {
	module: ConfluenceForgeUIExtensionType;
	action: () => void;
};

const getAccelerator = (module: ConfluenceForgeUIExtensionType): string =>
	module?.properties?.keyboardShortcut?.accelerator ?? '';

export const ForgeKeyboardShortcutVisualizer: FC<ForgeKeyboardShortcutVisualizerProps> = ({
	module,
	tooltip = '',
	children,
}) => {
	const accelerator = getAccelerator(module);
	if (accelerator && !RESERVED_SHORTCUTS.includes(accelerator)) {
		return (
			<Tooltip
				tag="span"
				testId={`forge-keyboard-shortcut-visualizer-${accelerator}`}
				content={<ShortcutVisualizer shortcut={accelerator} contentBefore={tooltip} />}
			>
				{children}
			</Tooltip>
		);
	}

	return <>{children}</>;
};

export const ForgeKeyboardShortcut: FC<ForgeKeyboardShortcutProps> = ({ module, action }) => {
	const accelerator = getAccelerator(module);
	if (accelerator && action && !RESERVED_SHORTCUTS.includes(accelerator)) {
		return (
			<span data-testid={`forge-keyboard-shortcut-${accelerator}`}>
				<GeneralShortcutListener
					accelerator={accelerator}
					listener={action}
					shortcutType={ShortcutType.Forge}
				/>
			</span>
		);
	}

	return null;
};
