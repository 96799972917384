import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { ContentToolsItemPlaceholder } from './ContentToolsItemPlaceholder';

export const LazyContentToolsItem = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContentToolsItem" */ './ContentToolsItem'))
			.ContentToolsItem,
});

export const LazyContentToolsItemWithPlaceholder = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContentToolsItem" */ './ContentToolsItem'))
			.ContentToolsItem,
	loading: () => <ContentToolsItemPlaceholder />,
});
