// we'd like to be able to check if the dialog is displaying right now
export const isDialogShown = () => {
	const portals = Array.from(document.getElementsByClassName('atlaskit-portal')).filter(
		(portal) => portal.firstChild,
	);

	const isAtlaskitDialogShown = Boolean(
		portals.filter((item) => item.querySelectorAll('[role="dialog"]').length).length,
	);

	const isLoomRecorderDialogShown = Boolean(
		document
			.getElementById('loom-sdk-record-overlay-shadow-root-id')
			?.shadowRoot?.getElementById('preview-recording'),
	);

	return isAtlaskitDialogShown || isLoomRecorderDialogShown;
};
