function keepTrying(fn: () => boolean | undefined, times: number, interval: number) {
	if (!fn() && times) setTimeout(() => keepTrying(fn, times - 1, interval), interval);
}

function isForgeModule(itemId: string) {
	return itemId.startsWith('ari:cloud:ecosystem::extension');
}

function escapeForgeModuleKey(itemId: string) {
	return itemId.replace(/\//g, '\\/').replace(/:/g, '\\:');
}

function getItemSelector(itemId: string | null | undefined) {
	if (!itemId || !itemId.length) {
		return;
	}

	let itemSelector = itemId[0] === '#' ? itemId : `#${itemId}`;

	if (isForgeModule(itemId)) {
		itemSelector = escapeForgeModuleKey(itemSelector);
	}

	return itemSelector;
}

function openMenu(menuId: string) {
	const triggerButton = document.querySelector(menuId) as HTMLElement;
	const isOpen = triggerButton.getAttribute('aria-expanded') === 'true';

	//only care about closed case, otherwise let legacy event listener handle
	if (!isOpen) {
		triggerButton.click();
	}
}

export function simulateItemClick(itemId: string) {
	const itemSelector = getItemSelector(itemId);
	if (!itemSelector) {
		return;
	}

	keepTrying(
		() => {
			const item = document.querySelector(itemSelector) as HTMLElement;
			if (item) {
				item.click();
				return true;
			}
		},
		100,
		5,
	);
}

export function simulateItemMouseOver(itemId: string) {
	const itemSelector = getItemSelector(itemId);
	if (!itemSelector) {
		return;
	}

	const event = new MouseEvent('mouseup', { bubbles: true, cancelable: true });
	document.querySelector(itemSelector)?.dispatchEvent(event);
}

export function openMenuClick() {
	openMenu('#tools-menu-trigger');
}

export function openContextMenuClick() {
	openMenu('#context-menu-other-apps');
}
