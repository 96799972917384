import gql from 'graphql-tag';

export const ConvertPageToFolderQuery = gql`
	query ConvertPageToFolderQuery($contentId: ID!, $spaceKey: String!) {
		space(key: $spaceKey) {
			id
			spaceTypeSettings {
				enabledContentTypes {
					isFoldersEnabled
				}
			}
		}
		singleContent(id: $contentId) {
			id
			title
			type
			status
			operations {
				operation
				targetType
			}
		}
	}
`;
