import { LoadableBackground, LoadableAfterPaint } from '@confluence/loadable';

export const ArchivedPageBanner = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ArchivedPageBanner" */ './ArchivedPageBannerV2'))
			.ArchivedPageBanner,
});

export const ArchivePagesLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ArchivePagesLoader" */ './ArchivePagesLoader'))
			.ArchivePagesLoader,
});

export const ArchiveUpsellDialog = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ArchiveUpsellDialog" */ './ArchiveUpsellDialog'))
			.ArchiveUpsellDialog,
});

export const UnarchivePagesDialogLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-UnarchivePagesLoader" */ './UnarchivePagesLoader'))
			.UnarchivePagesLoader,
});

export const BulkArchivePagesLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkArchivePagesLoader" */ './BulkArchivePagesLoader'
			)
		).BulkArchivePagesLoader,
});

export const ArchiveRemoteProcessContainer = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ArchiveRemoteProcessContainer" */ './ArchiveRemoteProcessContainer'
			)
		).ArchiveRemoteProcessContainer,
});

export const UpdateArchivedPageNoteDialog = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-UpdateArchivedPageNoteDialog" */ './UpdateArchivedPageNoteDialog'
			)
		).UpdateArchivedPageNoteDialog,
});

export { CONTENT_STATUS_ARCHIVED } from './archivingConstants';

export { ArchiveIcon } from './img/ArchiveIcon';
export {
	fireTrackEvent,
	fireUIClickEvent,
	TRACK_EVENT_TYPE,
	UI_EVENT_TYPE,
} from './AnalyticsEvents';
export { ShowArchiveCTAQuery } from './ShowArchiveCTAQuery.graphql';
export { canUnarchivePage, canDeletePage } from './PageOperations';

export { ArchivedPageNoteInput } from './ArchivedPageNoteInput';
