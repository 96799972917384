import { LoadableAfterPaint } from '@confluence/loadable';
export { PublishPageAsBlogSources } from './PublishPageAsBlogSourcesEnum';

export { PublishPageAsBlog as PublishPageAsBlogMutation } from './PublishPageAsBlogMutation.experimentalgraphql';

export { SpaceInfoQuery } from './SpaceInfoQuery.graphql';

export {
	publishFromPageToBlogEvent,
	publishPageAsBlogFailureEventAndFlag,
	knownEditorError,
	handleEditorPublishPageAsBlogError,
	blogifyErrorMessage,
} from './PublishPageAsBlogCleanup';
export { PublishEditPageAsBlogDropDownMenuItem } from './PublishEditPageAsBlogDropDownMenuItem';
export { useRetrieveEditorTitleAndContent } from './useRetrieveEditorTitleAndContent';
export { useConvertPageToBlog } from './useConvertPageToBlog';

export const PublishPageAsBlogDialog = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PublishPageAsBlogDialog" */ './PublishPageAsBlogDialog'
			)
		).PublishPageAsBlogDialog,
});

export type {
	PublishPageAsBlogMutation as PublishPageAsBlogMutationType,
	PublishPageAsBlogMutationVariables,
} from './__types__/PublishPageAsBlog';

export type { RetrieveEditorTitleAndContent } from './types';
