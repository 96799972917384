import { useMemo } from 'react';

import { useSessionData } from '@confluence/session-data';

import { useIsSpaceOrSiteAdmin } from './useIsSpaceOrSiteAdmin';

export const useIsAllowedToChangeOwner = (ownedByAccountId: string | null): boolean => {
	const { userId } = useSessionData();
	const isSpaceOrSiteAdmin = useIsSpaceOrSiteAdmin();

	return useMemo(() => {
		if (!userId) {
			return false;
		}
		const isCurrentUserOwner = ownedByAccountId === userId;
		return isSpaceOrSiteAdmin || isCurrentUserOwner;
	}, [isSpaceOrSiteAdmin, ownedByAccountId, userId]);
};
